import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 89px; // 89px width & 11px margins has 3 boxes fit perfectly on iPhone X screen
  margin: 0 11px 11px 11px; // No top margin & 11px bottom margin makes 11px gap between overflow rows
  height: 30px;
  font-size: 11px;
  border-radius: 20px;
  cursor: pointer;

  background-color: ${(props: { checked: boolean }) => props.checked ? "#3C3935" : "white"};
  color: ${(props: { checked: boolean }) => props.checked ? "white" : undefined};
  border: 1px solid ${(props: { checked: boolean }) => props.checked ? "none" : "#E0DBDB"};

  display: flex;
  align-items: center;
  justify-content: center;
`

type CheckboxProps = {
  text: string;
  checked: boolean;
  handleSelect: () => void;
}

const FlowGridCheckbox = (props: CheckboxProps) => {
  return (
    <Container onClick={props.handleSelect} checked={props.checked}>
      <input type="checkbox" name={props.text} checked={props.checked} style={{ display: "none" }}/>
      <label>{props.text}</label>
    </Container>
  );
};

export default FlowGridCheckbox;
