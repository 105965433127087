import React from "react";
import { MAX_WIDTH } from "../layouts/AppLayout";
import { Menu, SemanticWIDTHS } from "semantic-ui-react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

const NavContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  z-index: 10;
  background-color: white;
  border-top: 1px solid #e6e6e6;
`;

const NavItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-weight: bold;
  font-size: 12px;
  color: ${(props: { active: boolean }) =>
    props.active ? "#B7312C" : "#77838F"};
`;

const NavIcon = styled.img`
  height: 24px;
  width: 24px;
  margin-bottom: 8px;
`;

const pages = [
  {
    slug: "home",
    title: "Home",
    path: "/",
    icon: "/home-icons/home-menu-frame-icon.svg",
    iconActive: "/home-icons/home-menu-filled-icon.svg",
  },
  {
    slug: "my-workouts",
    title: "My Workouts",
    path: "/workouts",
    icon: "/home-icons/mat-menu-frame-icon.svg",
    iconActive: "/home-icons/mat-menu-filled-icon.svg",
  },
  {
    slug: "profile",
    title: "Profile",
    path: "/profile",
    icon: "/home-icons/user-menu-frame-icon.svg",
    iconActive: "/home-icons/user-menu-filled-icon.svg",
  },
];

const NavigationFooter = () => {
  const path = useLocation().pathname;
  const navigate = useNavigate();

  return (
    <NavContainer>
      <Menu widths={pages.length as SemanticWIDTHS} secondary fluid>
        {pages.map((page) => (
          <Menu.Item key={page.slug}>
            <NavItem
              active={path === page.path}
              onClick={() => navigate(page.path)}
            >
              <NavIcon src={path === page.path ? page.iconActive : page.icon} />
              {page.title}
            </NavItem>
          </Menu.Item>
        ))}
      </Menu>
    </NavContainer>
  );
};

export default NavigationFooter;
