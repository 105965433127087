import React from "react";
import { Link, useNavigate } from "react-router-dom";

type ConditionalLinkProps = {
  to?: string;
  action?: () => Promise<boolean>;
  condition?: boolean;
  children: React.ReactNode;
};

const ConditionalLink = (props: ConditionalLinkProps) => {
  const navigate = useNavigate();

  if (props.action != null && props.condition) {
    return (
      <div
        onClick={async () => {
          if (await props.action?.()) {
            if (props.to) {
              navigate(props.to);
            }
          }
        }}
      >
        {props.children}
      </div>
    );
  } else if (!!props.to && props.condition) {
    return <Link to={props.to}>{props.children}</Link>;
  } else {
    return <div>{props.children}</div>;
  }
};

export default ConditionalLink;
