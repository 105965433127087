import axios from "axios";
import ApiResponse from "./ApiResponse";

export const makeGetRequest = async <ReturnType>(url: string): Promise<ApiResponse<ReturnType>> => {
  try {
    const res = await axios.get(url);

    // Success
    return {
      success: true,
      status: res.status,
      data: res.data,
    };
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        // client received an error response (5xx, 4xx)
        return {
          success: false,
          status: err.response.status,
          error: err.message,
        };
      } else if (err.request) {
        // client never received a response, or request never left
        return {
          success: false,
          status: err.request.status,
          error: `Server error, please try again.\n${err.stack}`,
        };
      }
    }

    // anything else
    return {
      success: false,
      status: 500,
      error: `Unknown error: ${JSON.stringify(err)}`,
    };
  }
}
