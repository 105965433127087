import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import {
  handleToggleCheckBox,
  handleChooseRadio,
} from "../utils/checkboxHandlers";
import config from "../utils/config";
import {
  GetStudioLocalizationResponse,
  GetStudioResponse,
  placeholderLocalization,
  placeholderStudio,
} from "../api/studio/types";
import { getStudio } from "../api/studio";
import { IdNameSlugResponse } from "../api/types";
import httpRequest from "../utils/httpRequest";
import toast from "react-hot-toast";
import { Auth } from "aws-amplify";

/*
 * ---------------------------------------
 * ------------TYPES----------------------
 * ---------------------------------------
 */

type OnboardingPage = {
  stepNumber: number;
  backPagePath: string;
  nextPagePath: string;
  nextAction?: () => Promise<boolean>;
  question: string;
  headerBackgroundSrc: string;
  headerIconSrc: string;
  nextIsDisabled: boolean;
};

type CheckboxPage = OnboardingPage & {
  options: CheckboxOption[];
  handleSelect: (id: string) => void;
};

type CheckboxPageWithCustomField<CustomFieldType, HandlerParamType> =
  CheckboxPage & {
    customField: CustomFieldType;
    showCustomField: boolean;
    handleCustomField: (handler: HandlerParamType) => void;
  };

type CheckboxOption = {
  id: string;
  text: string;
  checked: boolean;
};

type Context = {
  localization: GetStudioLocalizationResponse;
  totalNumSteps: number;
  workout_levels: CheckboxPage;
  body_areas: CheckboxPage;
  activities: CheckboxPage;
  schedule_days: CheckboxPageWithCustomField<CheckboxOption[], string>;
  schedule_time: CheckboxPageWithCustomField<string, string>;
  personal_details: OnboardingPage & { fields: any };
};

export type { OnboardingPage as Page, CheckboxPage, CheckboxOption };

/*
 * ---------------------------------------
 * -------------PAGE VALUES---------------
 * ---------------------------------------
 */

const signupIconsUrl = `${config.ICONS_BASE_URL}signup/`;

const totalNumSteps = 5;

const initialOptions: { [key: string]: CheckboxOption[] } = {
  schedule_days: [
    { id: "1", text: "Occasionally", checked: false },
    { id: "2", text: "Every day", checked: false },
    { id: "3", text: "Pick specific days", checked: false },
  ],
  schedule_days_specific_days: [
    { id: "1", text: "Monday", checked: false },
    { id: "2", text: "Tuesday", checked: false },
    { id: "3", text: "Wednesday", checked: false },
    { id: "4", text: "Thursday", checked: false },
    { id: "5", text: "Friday", checked: false },
    { id: "6", text: "Saturday", checked: false },
    { id: "0", text: "Sunday", checked: false },
  ],
  schedule_time: [
    { id: "06:00", text: "6:00 am", checked: false },
    { id: "10:00", text: "10:00 am", checked: false },
    { id: "12:00", text: "12:00 pm", checked: false },
    { id: "16:00", text: "4:00 pm", checked: false },
    { id: "20:00", text: "8:00 pm", checked: false },
    { id: "custom", text: "Custom time", checked: false },
  ],
};

const scheduleDaysSpecificDaysOption = "Pick specific days";
const scheduleTimeCustomTimeOption = "Custom time";

/*
 * ---------------------------------------
 * ---------INITIAL CONTEXT---------------
 * ---------------------------------------
 */

const placeholderPage: OnboardingPage = {
  stepNumber: -1,
  backPagePath: "",
  nextPagePath: "",
  question: "",
  headerBackgroundSrc: "",
  headerIconSrc: "",
  nextIsDisabled: true,
};

const placeholderCheckboxPage: CheckboxPage = {
  ...placeholderPage,
  options: [],
  handleSelect: () => null,
};

const OnboardingQuestionsContext = createContext<Context>({
  localization: placeholderLocalization,
  totalNumSteps: -1,
  workout_levels: placeholderCheckboxPage,
  body_areas: placeholderCheckboxPage,
  activities: placeholderCheckboxPage,
  schedule_days: {
    ...placeholderCheckboxPage,
    customField: [],
    showCustomField: false,
    handleCustomField: () => null,
  },
  schedule_time: {
    ...placeholderCheckboxPage,
    customField: "",
    showCustomField: false,
    handleCustomField: () => null,
  },
  personal_details: {
    ...placeholderPage,
    fields: {},
  },
});

/*
 * ---------------------------------------
 * ------------CONTEXT--------------------
 * ---------------------------------------
 */

const OnboardingQuestionsContextProvider = () => {
  const [studio, setStudio] = useState<GetStudioResponse>(placeholderStudio);
  const { localization } = studio;
  useEffect(() => {
    (async () => {
      const response = await getStudio();
      if (response.success) {
        const newStudio = response.data;
        setStudio(newStudio);
        setWorkoutLevelsOptions(mapToCheckboxOption(newStudio.workoutLevels));
        setBodyAreasOptions(mapToCheckboxOption(newStudio.bodyAreas));
        setActivitiesOptions(mapToCheckboxOption(newStudio.activities));
      } else {
        // TODO handle error
      }
    })();
  }, []);

  const pages: { [pageName: string]: OnboardingPage } = {
    workout_levels: {
      stepNumber: 1,
      backPagePath: "",
      nextPagePath: "/signup/body_areas",
      question: localization.onboardingWorkoutLevelsQuestion,
      headerBackgroundSrc: `${signupIconsUrl}red-onboarding-side1-bundled.svg`,
      headerIconSrc: localization.onboardingWorkoutLevelsIcon,
      nextIsDisabled: true,
    },
    body_areas: {
      stepNumber: 2,
      backPagePath: "/signup/workout_levels",
      nextPagePath: "/signup/activities",
      question: localization.onboardingBodyAreasQuestion,
      headerBackgroundSrc: `${signupIconsUrl}red-onboarding-side2-bundled.svg`,
      headerIconSrc: localization.onboardingBodyAreasIcon,
      nextIsDisabled: true,
    },
    activities: {
      stepNumber: 3,
      backPagePath: "/signup/body_areas",
      nextPagePath: "/signup/schedule_days",
      question: localization.onboardingActivitiesQuestion,
      headerBackgroundSrc: `${signupIconsUrl}red-onboarding-side1-bundled.svg`,
      headerIconSrc: localization.onboardingActivitiesIcon,
      nextIsDisabled: true,
    },
    schedule_days: {
      stepNumber: 4,
      backPagePath: "/signup/activities",
      nextPagePath: "/signup/schedule_time",
      question: localization.onboardingScheduleDaysQuestion,
      headerBackgroundSrc: `${signupIconsUrl}red-onboarding-side2-bundled.svg`,
      headerIconSrc: localization.onboardingScheduleDaysIcon,
      nextIsDisabled: true,
    },
    schedule_time: {
      stepNumber: 4,
      backPagePath: "/signup/schedule_days",
      nextPagePath: "/signup/personal_details",
      question: localization.onboardingScheduleTimeQuestion,
      headerBackgroundSrc: `${signupIconsUrl}red-onboarding-side2-bundled.svg`,
      headerIconSrc: localization.onboardingScheduleTimeIcon,
      nextIsDisabled: true,
    },
    personal_details: {
      stepNumber: 5,
      backPagePath: "/signup/schedule_time",
      nextAction: async () => {
        try {
          let onboardingRequest = {} as any;

          // Move personal details across
          onboardingRequest.firstName =
            context.personal_details.fields.firstName;
          onboardingRequest.lastName = context.personal_details.fields.lastName;
          onboardingRequest.email = context.personal_details.fields.email;
          onboardingRequest.password = context.personal_details.fields.password;
          onboardingRequest.passwordConfirm =
            context.personal_details.fields.confirmPassword;

          // Set up arrays for multi-choice questions
          onboardingRequest.workoutLevelIds = [];
          onboardingRequest.bodyAreaIds = [];
          onboardingRequest.activityIds = [];

          context.workout_levels.options.forEach((option) => {
            if (option.checked) {
              onboardingRequest.workoutLevelIds.push(option.id);
            }
          });

          context.body_areas.options.forEach((option) => {
            if (option.checked) {
              onboardingRequest.bodyAreaIds.push(option.id);
            }
          });

          context.activities.options.forEach((option) => {
            if (option.checked) {
              onboardingRequest.activityIds.push(option.id);
            }
          });

          // Processing for schedules
          onboardingRequest.reminder = {};
          onboardingRequest.reminder.ruleType = parseInt(
            context.schedule_days.options.find((option) => {
              return option.checked;
            })?.id ?? "0",
            10
          );

          // Collect specific days requested
          if (onboardingRequest.reminder.ruleType == 3) {
            onboardingRequest.reminder.specificDays = [];
            context.schedule_days.customField.forEach((day) => {
              if (day.checked) {
                onboardingRequest.reminder.specificDays.push(
                  parseInt(day.id, 10)
                );
              }
            });
          }

          // Processing for the specific time of day
          let tmpSpecificTime = context.schedule_time.options.find((option) => {
            return option.checked;
          });
          if (tmpSpecificTime?.id === "custom") {
            onboardingRequest.reminder.timeOfDay =
              context.schedule_time.customField;
          } else {
            onboardingRequest.reminder.timeOfDay = tmpSpecificTime?.id;
          }

          // Send to Service
          const httpResponse = await httpRequest(
            "/api/onboarding",
            "post",
            onboardingRequest
          );

          const responseJson = await httpResponse.json();

          if (httpResponse.status === 200 && responseJson.success) {
            // Login + return
            Auth.signIn(onboardingRequest.email, onboardingRequest.password);

            return true;
          } else {
            if (responseJson.message) {
              toast.error(
                `Unable to create your account: ${responseJson.message}`
              );
            } else {
              toast.error("Unable to create your account");
            }
            return false;
          }
        } catch {
          return false;
        }
      },
      nextPagePath: "/signup/finish",
      question: localization.onboardingPersonalDetailsQuestion,
      headerBackgroundSrc: `${signupIconsUrl}red-onboarding-side2-bundled.svg`,
      headerIconSrc: localization.onboardingPersonalDetailsIcon,
      nextIsDisabled: true,
    },
  };

  const [workoutLevelsOptions, setWorkoutLevelsOptions] = useState<
    CheckboxOption[]
  >([]);
  const [bodyAreasOptions, setBodyAreasOptions] = useState<CheckboxOption[]>(
    []
  );
  const [activitiesOptions, setActivitiesOptions] = useState<CheckboxOption[]>(
    []
  );

  const [scheduleDaysSelectedOptions, setScheduleDaysSelectedOptions] =
    useState<CheckboxOption[]>(initialOptions.schedule_days);
  const [scheduleTimeSelectedOptions, setScheduleTimeSelectedOptions] =
    useState<CheckboxOption[]>(initialOptions.schedule_time);
  const [scheduleDaysSpecificDays, setScheduleDaysSpecificDays] = useState<
    CheckboxOption[]
  >(initialOptions.schedule_days_specific_days);
  const [scheduleTimeCustomTime, setScheduleTimeCustomTime] =
    useState<string>("07:30");

  // Personal Details fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const context = {
    localization,
    totalNumSteps,
    workout_levels: {
      ...pages.workout_levels,
      nextIsDisabled: !workoutLevelsOptions.some((option) => option.checked),

      options: workoutLevelsOptions,
      handleSelect: (id: string) =>
        handleToggleCheckBox(id, workoutLevelsOptions, setWorkoutLevelsOptions),
    },

    body_areas: {
      ...pages.body_areas,
      nextIsDisabled: !bodyAreasOptions.some((option) => option.checked),

      options: bodyAreasOptions,
      handleSelect: (id: string) =>
        handleToggleCheckBox(id, bodyAreasOptions, setBodyAreasOptions),
    },

    activities: {
      ...pages.activities,
      nextIsDisabled: !activitiesOptions.some((option) => option.checked),

      options: activitiesOptions,
      handleSelect: (id: string) =>
        handleToggleCheckBox(id, activitiesOptions, setActivitiesOptions),
    },

    schedule_days: {
      ...pages.schedule_days,
      nextIsDisabled: !scheduleDaysSelectedOptions.some(
        (option) => option.checked
      ),

      options: scheduleDaysSelectedOptions,
      handleSelect: (id: string) =>
        handleChooseRadio(
          id,
          scheduleDaysSelectedOptions,
          setScheduleDaysSelectedOptions
        ),

      customField: scheduleDaysSpecificDays,
      showCustomField: !!scheduleDaysSelectedOptions.find(
        (option) => option.text === scheduleDaysSpecificDaysOption
      )?.checked,
      handleCustomField: (id: string) =>
        handleToggleCheckBox(
          id,
          scheduleDaysSpecificDays,
          setScheduleDaysSpecificDays
        ),
    },

    schedule_time: {
      ...pages.schedule_time,
      nextIsDisabled: !scheduleTimeSelectedOptions.some(
        (option) => option.checked
      ),

      options: scheduleTimeSelectedOptions,
      handleSelect: (id: string) =>
        handleChooseRadio(
          id,
          scheduleTimeSelectedOptions,
          setScheduleTimeSelectedOptions
        ),

      customField: scheduleTimeCustomTime,
      showCustomField: !!scheduleDaysSelectedOptions.find(
        (option) => option.text === scheduleTimeCustomTimeOption
      )?.checked,
      handleCustomField: (newTime: string) =>
        setScheduleTimeCustomTime(newTime),
    },

    personal_details: {
      ...pages.personal_details,
      nextIsDisabled:
        [firstName, lastName, email, password, confirmPassword].some(
          (field) => !field
        ) || password !== confirmPassword,

      fields: {
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
      },
    },
  };

  return (
    <OnboardingQuestionsContext.Provider value={context}>
      <Outlet />
    </OnboardingQuestionsContext.Provider>
  );
};

const mapToCheckboxOption = (idNameSlugs: IdNameSlugResponse[]) =>
  idNameSlugs.map((idNameSlug) => ({
    id: idNameSlug.id,
    text: idNameSlug.name,
    checked: false,
  }));

export { OnboardingQuestionsContext, OnboardingQuestionsContextProvider };
