import React, { useContext } from "react";
import { OnboardingQuestionsContext } from "../../contexts/OnboardingQuestionsContextProvider";
import OnboardingPage from "../../components/OnboardingPage";
import CheckboxList from "../../components/CheckboxList";

const ScheduleDays = () => {
  const { totalNumSteps, schedule_days } = useContext(OnboardingQuestionsContext);

  const multipleDaysOption = schedule_days.options.find(option => option.text === "Pick specific days");

  return (
    <OnboardingPage page={schedule_days} totalNumSteps={totalNumSteps}>
      <CheckboxList type="list" options={schedule_days.options} handleSelect={schedule_days.handleSelect} />

      {multipleDaysOption && multipleDaysOption.checked &&
        <div style={{ marginTop: 32 }}>
          <CheckboxList type="flowGrid" options={schedule_days.customField} handleSelect={(id: string) => schedule_days.handleCustomField(id)} />
        </div>
      }

    </OnboardingPage>
  );
};

export default ScheduleDays;
