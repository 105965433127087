import React from "react";
import styled from "styled-components";

type WelcomeProps = {
  firstName: string;
};

const WelcomeContainer = styled.p`
  margin-bottom: 0;
  color: black;
  font-size: 20px;
  font-weight: bold;
`;

const Welcome = (props: WelcomeProps) => {
  return <WelcomeContainer>Hey {props.firstName}</WelcomeContainer>;
};

export default Welcome;
