import React, { useEffect, useState } from "react";
import httpRequest from "../utils/httpRequest";
import Lottie from "react-lottie";
import animationData from "../assets/loading.json";
import styled from "styled-components";

import Spacer from "./Spacer";
import Welcome from "./Welcome";
import CategoriesCarousel from "./CategoriesCarousel";
import VideoCardCarousel from "./VideoCardCarousel";

type ManifestHandlerProps = {
  api: string;
};

const elementMap = {
  Spacer,
  Welcome,
  CategoriesCarousel,
  VideoCardCarousel,
} as Record<string, any>;

const UnknownElement = styled.div`
  border: 1px solid red;
  height: 1rem;
`;

const ManifestItemHandler = (props: any) => {
  const ThisElement = elementMap[props.element] ?? UnknownElement;

  return <ThisElement {...props.properties} />;
};

const ManifestHandler = (props: ManifestHandlerProps) => {
  const [manifest, setManifest] = useState(null as any);

  useEffect(() => {
    (async () => {
      const manifest = await httpRequest("/api/home");

      setManifest(await manifest.json());
    })();
  }, []);

  return (
    <>
      {manifest && manifest.items ? (
        <>
          {manifest.items.map((item: any) => {
            return <ManifestItemHandler {...item} />;
          })}
        </>
      ) : (
        <>
          <Lottie
            options={{ loop: true, autoplay: true, animationData }}
            speed={0.75}
          ></Lottie>
        </>
      )}
    </>
  );
};

export default ManifestHandler;
