import ApiResponse from "../api/ApiResponse";
import { useEffect, useState } from "react";

type useGetProps<DataType> = {
  initialDataState: DataType;
  fetchMethod: () => Promise<ApiResponse<DataType>>;
  onError?: (error: ApiResponse<DataType> & { success: false }) => void;
  dependency?: any;
}

const useGet = <DataType>(props: useGetProps<DataType>) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DataType>(props.initialDataState);

  const [refetchVar, setRefetchVar] = useState(0);
  const refetch = () => {
    setRefetchVar(prevState => prevState + 1);
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await props.fetchMethod();
      if (response.success) {
        setData(response.data);
      } else {
        props.onError && props.onError(response);
      }
      setLoading(false);
    })();
  }, [props.dependency, refetchVar]);

  return {
    loading,
    data,
    setData,
    refetch,
  }
}

export default useGet;
