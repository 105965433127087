import React from "react";
import styled from "styled-components";

const HeaderContainer = styled.div`
  width: 100%;
  max-height: ${(props: { backgroundMaxHeight: string }) => props.backgroundMaxHeight};
  position: relative; // Needed for CenterOnBackground div to have correct height
`

const CenterOnBackground = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StretchBackground = styled.img`
  width: 100%;
  height: 100%;
`

const ConstrainedWidthIcon = styled.img`
  width: ${(props: { iconWidth: string, iconMaxWidth: string }) =>
          props.iconWidth};
  max-width: ${(props: { iconWidth: string, iconMaxWidth: string  }) =>
          props.iconMaxWidth}
`

type OnboardingHeaderProps = {
  backgroundSrc: string;
  iconSrc: string;
  backgroundMaxHeight: string;
  iconWidth: string;
  iconMaxWidth: string;
}

const IconHeader = (props: OnboardingHeaderProps) => {
  return (
    <HeaderContainer backgroundMaxHeight={props.backgroundMaxHeight}>
      <StretchBackground alt="header-background" src={props.backgroundSrc} />

      <CenterOnBackground>
        <ConstrainedWidthIcon
          alt="icon"
          src={props.iconSrc}
          iconWidth={props.iconWidth}
          iconMaxWidth={props.iconMaxWidth}
        />
      </CenterOnBackground>
    </HeaderContainer>
  );
};

export default IconHeader;
