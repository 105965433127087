import React from "react";
import IconHeader from "./IconHeader";
import ProgressNavigator from "./ProgressNavigator";
import { Page as PageType } from "../contexts/OnboardingQuestionsContextProvider";
import styled from "styled-components";

const FlexDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  margin: 32px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Question = styled.p`
  font-size: 17px;
  margin-bottom: 32px;
`;

type OnboardingPageProps = {
  page: PageType;
  totalNumSteps: number;
  children: React.ReactNode;
};

const OnboardingPage = (props: OnboardingPageProps) => {
  return (
    <FlexDiv>
      <IconHeader
        backgroundSrc={props.page.headerBackgroundSrc}
        iconSrc={props.page.headerIconSrc}
        backgroundMaxHeight="25%"
        iconWidth="25%"
        iconMaxWidth="150px"
      />

      <Container>
        <div style={{ flexGrow: 1 }}>
          <Question>{props.page.question}</Question>

          {props.children}
        </div>

        <div>
          <ProgressNavigator
            current={props.page.stepNumber}
            total={props.totalNumSteps}
            backPage={props.page.backPagePath}
            nextAction={props.page.nextAction}
            nextPage={props.page.nextPagePath}
            backIsHidden={props.page.stepNumber === 1}
            nextIsDisabled={props.page.nextIsDisabled}
          />
        </div>
      </Container>
    </FlexDiv>
  );
};

export default OnboardingPage;
