const config = {
  API_URL: "",
  LOG_REQUESTS: true,
  AMPLIFY: {
    Auth: {
      region: "ap-southeast-2",
      userPoolId: "ap-southeast-2_GVrpu7nVz",
      userPoolWebClientId: "ou1omt7oklcbldq1qfj2seaog",
    },
  },
  ICONS_BASE_URL: "https://s.mvk.nz/momentum/",
};

export default config;
