import React, { useContext } from "react";
import { OnboardingQuestionsContext } from "../../contexts/OnboardingQuestionsContextProvider";
import OnboardingPage from "../../components/OnboardingPage";
import CheckboxList from "../../components/CheckboxList";
import styled from "styled-components";

const CenterInDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
`

const TimePicker = styled.input`
  color: inherit;
  border: none;
  background: none;
  font-size: 24px;
  height: 100px;
`

const ScheduleTime = () => {
  const { totalNumSteps, schedule_time } = useContext(OnboardingQuestionsContext);

  const customTimeOption = schedule_time.options.find(option => option.text === "Custom time");
  const otherOptions = schedule_time.options.filter(option => option.text !== "Custom time");

  return (
    <OnboardingPage page={schedule_time} totalNumSteps={totalNumSteps}>
      <CheckboxList type="flowGrid" options={otherOptions} handleSelect={schedule_time.handleSelect} />

      {customTimeOption && (
        <>
          <div style={{ marginTop: 11 }}>
            <CheckboxList type="list" options={[customTimeOption]} handleSelect={schedule_time.handleSelect} />
          </div>

          {customTimeOption.checked && (
            <CenterInDiv>
              {/* TODO replace with time picker that better matches design */}
              <TimePicker
                type="time"
                value={schedule_time.customField}
                onChange={(event) => schedule_time.handleCustomField(event.target.value)}/>
            </CenterInDiv>
          )}
        </>
      )}
    </OnboardingPage>
  );
};

export default ScheduleTime;
