import React from "react";
import { Icon } from "semantic-ui-react";
import styled, { css } from "styled-components";
import { VideoCardResponse } from "../api/workouts/types";
import TagCollection from "./TagCollection";
import Spacer from "./Spacer";
import { useNavigate } from "react-router-dom";

const Card = styled.div`${(props: { data: VideoCardResponse }) => css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  border-radius: 14px;
  color: white;

  margin-right: 14px;

  background-image: url(${props.data.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 14px;
    // Add 57% (hex 92) opacity to background color
    background-color: ${props.data.primaryColor}92;
  }
  & * {
    z-index: 1;
  }

  &.large {
    width: 185px;
    height: 235px;
    padding: 14px;
  }
  &.regular {
    width: 136px;
    height: 173px;
    padding: 10px;
  }
`}`

const Title = styled.p`
  margin-bottom: 0;
  font-weight: bold;

  &.large {
    font-size: 16px;
  }
  &.regular {
    font-size: 11px;
  }
`

const Subtitle = styled.p`
  font-size: 9px;
  font-weight: bold;
`

type VideoCardProps = {
  data: VideoCardResponse;
  size?: "large" | "regular";
}
const defaultSize = "regular";

const VideoCard = (props: VideoCardProps) => {
  const navigate = useNavigate();

  let durationMinutes = Math.round(props.data.duration / 60);
  if (durationMinutes === 0) {
    durationMinutes = 1;
  }

  const tags: VideoCardResponse["tags"] = [
    {
      id: "zzz",
      name: `${durationMinutes} min`,
      slug: `${durationMinutes}-min`,
    },
    ...props.data.tags
  ];

  return (
    <Card data={props.data} className={props.size || defaultSize} onClick={() => navigate(`/workouts/${props.data.id}`)}>
      <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Icon name={props.data.isFavourite ? "heart" : "heart outline"} size={props.size === "large" ? "large" : undefined} fitted />
        <Icon name={props.data.isScheduled ? "clock" : "clock outline"} size={props.size === "large" ? "large" : undefined} fitted />
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Icon.Group size="big" style={{ cursor: "pointer" }}>
          {props.size === "large" ? (
            <>
              <Icon name="circle outline" size="big" />
              <Icon name="play" size="small" />
            </>
          ) : (
            <>
              <Icon name="circle outline" size="large" />
              <Icon name="play" size="mini" />
            </>
          )}
        </Icon.Group>
      </div>

      <div>
        <Title className={props.size || defaultSize}>{props.data.name}</Title>
        {props.size === "large" ? (
          <>
            <Spacer height={4} />
            <TagCollection tags={tags.map(tag => tag.name)} size="mini" secondary />
          </>
        ) : (
          <>
            <Subtitle>{`${durationMinutes} min`}</Subtitle>
          </>
        )}
      </div>
    </Card>
  );
};

export default VideoCard;
