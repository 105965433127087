import React from "react";
import styled from "styled-components";
import { Checkbox as SemanticUiCheckbox} from "semantic-ui-react";

const Container = styled.div`
  padding: 12px;
  box-shadow: 0 4px 16px #E2E2E2;
  border-radius: 30px;
  cursor: pointer;
`

type CheckboxProps = {
  text: string;
  checked: boolean;
  handleSelect: () => void;
}

const Checkbox = (props: CheckboxProps) => {
  return (
    <Container onClick={props.handleSelect}>
      {/* TODO try to use custom svg for checkmark */}
      <SemanticUiCheckbox label={props.text} checked={props.checked} />
    </Container>
  );
};

export default Checkbox;
