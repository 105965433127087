import React from "react";
import styled from "styled-components";
import colors from "../utils/colors";

const ProgressMarker = styled.div`
  width: 14px;
  height: 6px;
  border-radius: 3px;
  margin: 3px;
  background-color: ${props => props.color};
`

type ProgressBarProps = {
  current: number;
  total: number;
}

const ProgressBar = (props: ProgressBarProps) => {
  // create temp array with length === total to use in map
  const temps = [];
  for (let i = 0; i < props.total; i++) {
    temps.push(-1);
  }

  return (
    <div style={{ display: "flex" }}>
      {temps.map((_, index) => (
        <ProgressMarker key={index} color={index + 1 <= props.current ? colors.red : colors.grey} />
      ))}
    </div>
  );
};

export default ProgressBar;
