import React, { useState } from "react";
import ContainerWithNavigationFooter from "../components/ContainerWithNavigationFooter";
import Header from "../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { VideoResponse } from "../api/workouts/types";
import { getWorkout } from "../api/workouts";
import { Checkbox, Icon, Loader, Message } from "semantic-ui-react";
import Spacer from "../components/Spacer";
import RoundedButton from "../components/RoundedButton";
import useGet from "../hooks/useGet";

const Card = styled.div`${(props: { image: string, primaryColor: string }) => css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  border-radius: 14px;
  color: white;

  background-image: url(${props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 14px;
    // Add 57% (hex 92) opacity to background color
    background-color: ${props.primaryColor}92;
  }
  & * {
    z-index: 1;
  }

  width: 100%;
  height: 235px;
  padding: 14px;
`}`

const CenterInDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const DateTimePicker = styled.input`
  color: inherit;
  border: none;
  background: none;
  font-size: 24px;
  height: 215px;
`

const ButtonText = styled.p`
  color: white;
  font-size: 15px;
  font-weight: bold;
`

const Workout = () => {
  const marginX = 14;
  const navigate = useNavigate();
  const { id } = useParams();
  // Throw error if id is undefined for type safety
  // This will never happen since the component is only rendered when id is defined
  if (!id) {
    throw new Error("UNEXPECTED ERROR: id param is falsy in pages/ScheduleWorkout.tsx");
  }

  const [apiError, setApiError] = useState("");
  const {
    loading,
    data: workout,
    setData,
  } = useGet<VideoResponse | null>({
    initialDataState: null,
    fetchMethod: async () => {
      setApiError("");
      return await getWorkout(id);
    },
    onError: (response) => {
      setApiError(response.error);
      setData(null);
    },
    dependency: id,
  });
  const [scheduleDateTime, setScheduleDateTime] = useState("");
  const [recurringWeekly, setRecurringWeekly] = useState(false);

  const handleSubmit = async () => {
    // TODO send API request, redirect to somewhere?
  }

  return (
    <>
      <Header title="Schedule Workout" backButton />
      <ContainerWithNavigationFooter marginX={marginX}>
        {loading ? (
          <Loader active />
        ) : !workout || !!apiError ? (
          // API does not return successfully (server error or not found)
          <Message error header="Something went wrong." content={apiError} />
        ) : (
          <>
            <Card image={workout.videoCard.image} primaryColor={workout.videoCard.primaryColor} onClick={() => navigate(`/workouts/${workout.id}`)}>
              <div><Icon name={workout.videoCard.isFavourite ? "heart" : "heart outline"} size="large" fitted /></div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <Icon.Group size="big" style={{ cursor: "pointer" }}>
                  <Icon name="circle outline" size="big" />
                  <Icon name="play" size="small" />
                </Icon.Group>
              </div>

              {/* Empty div to align play button in middle of div */}
              <div />
            </Card>

            <Spacer height={24} />

            {/* TODO replace with datetime picker that better matches design */}
            <CenterInDiv>
              <DateTimePicker
                type="datetime-local"
                value={scheduleDateTime}
                onChange={(event) => setScheduleDateTime(event.target.value)}
              />
            </CenterInDiv>

            <Spacer height={10} />

            <Checkbox toggle label="Recurring Weekly" checked={recurringWeekly} onChange={() => setRecurringWeekly(prevState => !prevState)} />

            <Spacer height={50} />

            <CenterInDiv>
              <div style={{ width: 200 }} onClick={handleSubmit}>
                <RoundedButton color={"black"}>
                  <ButtonText>Schedule workout</ButtonText>
                </RoundedButton>
              </div>
            </CenterInDiv>
          </>
        )}
      </ContainerWithNavigationFooter>
    </>
  );
};

export default Workout;
