import React from "react";
import CheckboxList from "./CheckboxList";
import { CheckboxPage as CheckboxPageType } from "../contexts/OnboardingQuestionsContextProvider";
import OnboardingPage from "./OnboardingPage";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Skip = styled(Link)`
  color: inherit; // Use system-wide font color
  // TODO investigate why margin-top doesn't work for this. Probably something related to flex-grow
  position: relative;
  top: 32px;
`

type OnboardingCheckboxPageProps = {
  page: CheckboxPageType;
  totalNumSteps: number;
  skippable?: boolean;
}

const OnboardingCheckboxPage = (props: OnboardingCheckboxPageProps) => {
  return (
    <OnboardingPage page={props.page} totalNumSteps={props.totalNumSteps}>
      <CheckboxList options={props.page.options} handleSelect={props.page.handleSelect} />

      {props.skippable && <Skip to={props.page.nextPagePath}>Skip</Skip>}
    </OnboardingPage>
  );
};

export default OnboardingCheckboxPage;
