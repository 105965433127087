import styled, { css } from "styled-components";

type SpacerProps = {
  height?: number;
  width?: number;
}

const SpacerDiv = styled.div`${(props: SpacerProps) => css`
  height: ${props.height ? `${props.height}px` : "100%"};
  width: ${props.width ? `${props.width}px` : "100%"};
`}`

const Spacer = (props: SpacerProps) => (
  <SpacerDiv {...props} />
);

export default Spacer;
