import React from "react";
import Navigation from "../components/Navigation";
import ContainerWithNavigationFooter from "../components/ContainerWithNavigationFooter";
import Header from "../components/Header";

const Profile = () => {
  return (
    <>
      <Header title="Profile" />
      <ContainerWithNavigationFooter>
        <Navigation />
      </ContainerWithNavigationFooter>
    </>
  );
};

export default Profile;
