import { Toaster } from "react-hot-toast";

const AppContainer = (props: any) => {
  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <>{props.children}</>
    </>
  );
};
export default AppContainer;
