import React, { useState } from "react";
import ContainerWithNavigationFooter from "../components/ContainerWithNavigationFooter";
import Header from "../components/Header";
import { VideoCardResponse } from "../api/workouts/types";
import { getMyWorkouts } from "../api/workouts";
import VideoCardCarousel from "../components/VideoCardCarousel";
import useGet from "../hooks/useGet";
import { Loader, Message } from "semantic-ui-react";

const MyWorkouts = () => {
  const [apiError, setApiError] = useState("");
  const {
    loading,
    data: videoCards,
    setData,
  } = useGet<VideoCardResponse[]>({
    initialDataState: [],
    fetchMethod: async () => {
      setApiError("");
      return await getMyWorkouts();
    },
    onError: (response) => {
      setApiError(response.error);
      setData([]);
    },
  });

  const scheduledVideoCards = videoCards.filter(videoCard => videoCard.isScheduled);
  const favouriteVideoCards = videoCards.filter(videoCard => videoCard.isFavourite);

  return (
    <ContainerWithNavigationFooter>
      <Header title="My Workouts" />
      {loading ? (
        <Loader active />
      ) : !!apiError ? (
        <Message error header="Something went wrong." content={apiError} />
      ) : (
        <>
          <VideoCardCarousel title="Your Scheduled Workouts" videoCards={scheduledVideoCards} footerLink={{ title: "View All", to: "/workouts/all" }} size="large" withAddCard />
          <VideoCardCarousel title="Your Favourite Workouts" videoCards={favouriteVideoCards} footerLink={{ title: "View All", to: "/workouts/all" }} size="large" />
        </>
      )}
    </ContainerWithNavigationFooter>
  );
};

export default MyWorkouts;
