import React from "react";
import styled from "styled-components";

const Title = styled.p`
  color: #4B4B4B;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0;
`

const ScrollContainer = styled.div`
  // Scroll items horizontally on overflow
  display: flow;
  overflow-x: scroll;
  overflow-y: visible;

  // Hide scrollbar but keep functionality
  &::-webkit-scrollbar { /* Chrome, Safari and Opera */
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  // Children reach the edge of the screen / container on scroll
  // Keep margin for first and last children (when reaching start or end of scroll)
  margin: 0 -28px;
  & > :first-child {
    margin-left: 28px;
  }
  & > :last-child {
    margin-right: 28px;
  }
`

const Category = styled.div`
  width: 100px;
  height: 100px;

  border-radius: 14px;
  box-shadow: 0 4px 16px #E2E2E2;
  background-color: white;

  padding: 8px;
  // Vertical spacing needs to be on Category element rather than a Spacer so the box shadow is not cut off
  margin: 14px 14px 24px 0;

  font-size: 13px;
  font-weight: bold;
  color: #46494C;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`

type CategoriesCarouselProps = {
  title: string;
  categories: string[];
}

const CategoriesCarousel = (props: CategoriesCarouselProps) => {
  return (
    <div>
      <Title>{props.title}</Title>
      <ScrollContainer>
        {props.categories.map(bodyArea => (
          <Category><p>{bodyArea}</p></Category>
        ))}
      </ScrollContainer>
    </div>
  );
}

export default CategoriesCarousel;