import React from "react";
import styled, { css } from "styled-components";
import VideoCard from "./VideoCard";
import { VideoCardResponse } from "../api/workouts/types";
import { Link } from "react-router-dom";
import Spacer from "./Spacer";

const Title = styled.p`
  color: #4B4B4B;
  font-size: 15px;
  font-weight: bold;
  margin: 0;
`

const Carousel = styled.div`${(props: { marginX: number }) => css`
  // Scroll items horizontally on overflow
  display: flow;
  overflow-x: scroll;

  // Hide scrollbar but keep functionality
  &::-webkit-scrollbar { /* Chrome, Safari and Opera */
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  // Children reach the edge of the screen / container on scroll
  // Keep margin for first and last children (when reaching start or end of scroll)
  margin: 0 -${props.marginX}px;
  & > :first-child {
    margin-left: ${props.marginX}px;
  }
  & > :last-child {
    margin-right: ${props.marginX}px;
  }
`}`

const AlignRight = styled.div`
  width: 100%;
  text-align: right;
`

const SmallLink = styled(Link)`
  color: #44474A;
  font-size: 12px;
`

const EmptyCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 14px;
  text-align: center;

  &.large {
    width: 185px;
    height: 235px;
    padding: 14px;
  }
  &.regular {
    width: 136px;
    height: 173px;
    padding: 10px;
  }

  background-color: #F5F5F5;
  // dashed border from https://kovart.github.io/dashed-border-generator/
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23ACA6A6' stroke-width='3' stroke-dasharray='15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 20px;
`

type VideoCardCarouselProps = {
  title: string;
  videoCards: VideoCardResponse[];
  footerLink?: {
    title: string;
    to: string;
  }
  marginX?: number;
  size?: "large" | "regular";
  withAddCard?: boolean;
}
const defaultSize = "regular";

const VideoCardCarousel = (props: VideoCardCarouselProps) => {
  const marginX = props.marginX || 28;

  return (
    <div>
      <Title>{props.title}</Title>
      <Spacer height={15} />
      <Carousel marginX={marginX}>
        {props.withAddCard && (
          <EmptyCard className={props.size || defaultSize}>
            <img src="/home-icons/plus-icon.svg" alt="plus-icon" />
          </EmptyCard>
        )}
        {props.videoCards.length ? (
          props.videoCards.map(videoCard =>
            <VideoCard data={videoCard} key={videoCard.id} size={props.size || defaultSize} />
          )
        ) : (
          <EmptyCard className={props.size || defaultSize}>
            <p>No Workouts Found</p>
          </EmptyCard>
        )}
      </Carousel>
      {!!props.footerLink ? (
        <>
          <Spacer height={4} />
          <AlignRight>
            <SmallLink to={props.footerLink.to}>{props.footerLink.title}</SmallLink>
          </AlignRight>
          <Spacer height={8} />
        </>
      ) : (
        <Spacer height={24} />
      )}
    </div>
  );
};

export default VideoCardCarousel;
