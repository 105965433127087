import React, { useEffect, useState } from "react";
import VideoCardCarousel from "../components/VideoCardCarousel";
import { getAllWorkouts } from "../api/workouts";
import { VideoCardResponse } from "../api/workouts/types";
import ContainerWithNavigationFooter from "../components/ContainerWithNavigationFooter";
import CategoriesCarousel from "../components/CategoriesCarousel";
import styled from "styled-components";
import Spacer from "../components/Spacer";
import httpRequest from "../utils/httpRequest";

const Welcome = styled.p`
  margin-bottom: 0;
  color: black;
  font-size: 20px;
  font-weight: bold;
`;

const Home = () => {
  const [videoCards, setVideoCards] = useState<VideoCardResponse[]>([]);
  const firstName = "Carole";

  // TODO handle loading and error cases
  useEffect(() => {
    (async () => {
      const allWorkouts = await getAllWorkouts();
      setVideoCards(allWorkouts.success ? allWorkouts.data[0].videoCards : []);

      const manifest = await httpRequest("/api/home");

      console.log(manifest);
    })();
  }, []);

  return (
    <ContainerWithNavigationFooter>
      <Spacer height={16} />
      <Welcome>Hey {firstName}</Welcome>
      <Spacer height={25} />

      <VideoCardCarousel
        title="Here’s mini Paul’s top workouts for you"
        videoCards={videoCards}
        footerLink={{ title: "View All", to: "/workouts/all" }}
        size="large"
      />

      {/* TODO retrieve data from API */}
      <CategoriesCarousel
        title="Stretches for specific areas"
        categories={["Lower Back", "Hamstrings"]}
      />
      <CategoriesCarousel
        title="Balance, unwind and straighten out after..."
        categories={["Road Bike", "Running", "Gardening"]}
      />
    </ContainerWithNavigationFooter>
  );
};

export default Home;
