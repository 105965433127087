import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  height: 46px;
  border-radius: 23px;
  padding: 0 16px;
  width: 100%;
  border: none;
  box-shadow: 0 4px 16px #E2E2E2;
  background-color: ${props => props.color};
  cursor: ${props => !props.disabled ? "pointer" : undefined};
`

const CenterChildren = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

type ButtonProps = {
  color: string;
  children: any;
  disabled?: boolean;
}

const RoundedButton = (props: ButtonProps) => {
  return (
    <StyledButton disabled={props.disabled} color={props.color}>
      <CenterChildren>
        {props.children}
      </CenterChildren>
    </StyledButton>
  )
}

export default RoundedButton;
