import React, { useState } from "react";
import ContainerWithNavigationFooter from "../components/ContainerWithNavigationFooter";
import Header from "../components/Header";
import { GetWorkoutCollectionResponse } from "../api/workouts/types";
import { getAllWorkouts } from "../api/workouts";
import VideoCardCarousel from "../components/VideoCardCarousel";
import useGet from "../hooks/useGet";
import { Loader, Message } from "semantic-ui-react";

const MyWorkouts = () => {
  const [apiError, setApiError] = useState("");
  const {
    loading,
    data: videoCardCollections,
    setData,
  } = useGet<GetWorkoutCollectionResponse[]>({
    initialDataState: [],
    fetchMethod: async () => {
      setApiError("");
      return await getAllWorkouts();
    },
    onError: (response) => {
      setApiError(response.error);
      setData([]);
    },
  });

  return (
    <>
      <Header title="All Workouts" backButton />
      <ContainerWithNavigationFooter>
        {loading ? (
          <Loader active />
        ) : !!apiError ? (
          // API does not return successfully
          <Message error header="Something went wrong." content={apiError} />
        ) : videoCardCollections.length ? (
          // API returns successfully, videoCardCollection array is not empty, sub-arrays of videoCards may be empty
          videoCardCollections.map(videoCardCollection => (
            <VideoCardCarousel key={videoCardCollection.slug} title={videoCardCollection.name} videoCards={videoCardCollection.videoCards} />
          ))
        ) : (
          // API returns successfully, but videoCardCollection array is empty
          <Message
            error
            header="No workouts were found."
            content="Please try again later."
          />
        )}
      </ContainerWithNavigationFooter>
    </>
  );
};

export default MyWorkouts;
