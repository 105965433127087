import React, { useContext } from "react";
import { OnboardingQuestionsContext } from "../../contexts/OnboardingQuestionsContextProvider";
import OnboardingCheckboxPage from "../../components/OnboardingCheckboxPage";

const Activities = () => {
  const { totalNumSteps, activities } = useContext(OnboardingQuestionsContext);

  return <OnboardingCheckboxPage
    page={activities}
    totalNumSteps={totalNumSteps}
    skippable={true}
  />;
};

export default Activities;
