import React, { useState } from "react";
import ContainerWithNavigationFooter from "../components/ContainerWithNavigationFooter";
import Header from "../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { VideoResponse } from "../api/workouts/types";
import { getWorkout } from "../api/workouts";
import TagCollection from "../components/TagCollection";
import VideoCardCarousel from "../components/VideoCardCarousel";
import { Icon, Loader, Message } from "semantic-ui-react";
import Spacer from "../components/Spacer";
import useGet from "../hooks/useGet";

const PlayerContainer = styled.div`
  position: relative;
  padding: 56.25% 0 0 0;
`

const Player = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
`

const Workout = () => {
  const navigate = useNavigate();
  // Throw error if id is undefined for type safety
  // This will never happen since the component is only rendered when id is defined
  const { id } = useParams();
  if (!id) {
    throw new Error("UNEXPECTED ERROR: id param is falsy in pages/Workout.tsx");
  }

  const marginX = 14;
  const [apiError, setApiError] = useState("");
  const {
    loading,
    data: workout,
    setData,
    refetch,
  } = useGet<VideoResponse | null>({
    initialDataState: null,
    fetchMethod: async () => {
      // TODO move error text into hook
      setApiError("");
      return await getWorkout(id);
    },
    onError: (response) => {
      setApiError(response.error);
      setData(null);
    },
    dependency: id,
  });

  const toggleFavourite = async () => {
    // TODO send API request, refetch data
  }

  return (
    <>
      <Header title={id} backButton />
      <ContainerWithNavigationFooter marginX={marginX}>
        {loading ? (
          <Loader active />
        ) : !workout || !!apiError ? (
          // API does not return successfully (server error or not found)
          <Message error header="Something went wrong." content={apiError} />
        ) : (
          // API returns successfully
          <>
            <PlayerContainer>
              <Player
                title={workout.videoTitle}
                src={workout.videoSrc}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              />
            </PlayerContainer>

            <Spacer height={14} />

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <TagCollection tags={workout.primaryTags.map(tag => tag.name)} size="large" />

              <div style={{  display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
                <Icon name={workout.videoCard.isFavourite ? "heart" : "heart outline"} size="large" fitted link onClick={toggleFavourite} />
                <Spacer width={8} />
                <Icon name={workout.videoCard.isScheduled ? "clock" : "clock outline"} size="large" fitted link onClick={() => navigate(`/workouts/${workout.id}/schedule`)}/>
                <Spacer width={8} />
              </div>
            </div>

            <TagCollection tags={workout.secondaryTags.map(tag => tag.name)} secondary />

            <Spacer height={42} />

            <VideoCardCarousel title="Similar workouts" videoCards={workout.similarWorkouts} marginX={marginX} />
          </>
        )}
      </ContainerWithNavigationFooter>
    </>
  );
};

export default Workout;
