import React, { useContext, useState } from "react";
import styled from "styled-components";
import config from "../../utils/config";
import { OnboardingQuestionsContext } from "../../contexts/OnboardingQuestionsContextProvider";
import Lottie from "react-lottie";
import animationData from "../../assets/checkGrey.json";

const HeaderContainer = styled.div`
  width: 100%;
  max-height: ${(props: { backgroundMaxHeight: string }) =>
    props.backgroundMaxHeight};
  position: relative; // Needed for CenterOnBackground div to have correct height
`;

const CenterOnBackground = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StretchBackground = styled.img`
  width: 100%;
  height: 100%;
`;

const ConstrainedWidthDiv = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: ${(props: { iconWidth: string; iconMaxWidth: string }) =>
    props.iconWidth};
  max-width: ${(props: { iconWidth: string; iconMaxWidth: string }) =>
    props.iconMaxWidth};
`;

const ConstrainedWidthIcon = styled.img`
  width: ${(props: { iconWidth: string; iconMaxWidth: string }) =>
    props.iconWidth};
  max-width: ${(props: { iconWidth: string; iconMaxWidth: string }) =>
    props.iconMaxWidth};
`;

const FlexDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FinishContainer = styled.div`
  margin: 32px 32px 32px 32px;
  text-align: center;
`;

const FinishTitle = styled.div`
  font-size: 40px;
  font-weight: bold;
`;

const Finish = () => {
  const { localization } = useContext(OnboardingQuestionsContext);

  const backgroundSrc = `${config.ICONS_BASE_URL}signup/red-intro-side2-bundled.svg`;
  const backgroundMaxHeight = "60%";
  const iconWidth = "80%";
  const iconMaxWidth = "450px";

  const [hasNavigated, setHasNavigated] = useState(false);

  const completed = () => {
    if (!hasNavigated) {
      setHasNavigated(true);
      setTimeout(() => {
        window.location.href = "/";
      }, 750);
    }
  };

  return (
    <FlexDiv onClick={completed}>
      <HeaderContainer backgroundMaxHeight={backgroundMaxHeight}>
        <StretchBackground alt="header-background" src={backgroundSrc} />

        <CenterOnBackground>
          <ConstrainedWidthDiv
            iconWidth={iconWidth}
            iconMaxWidth={iconMaxWidth}
          >
            <ConstrainedWidthIcon
              alt="icon"
              src={localization.onboardingFinishIcon}
              iconWidth={iconWidth}
              iconMaxWidth={iconMaxWidth}
            />
          </ConstrainedWidthDiv>
        </CenterOnBackground>
      </HeaderContainer>

      <FinishContainer>
        <FinishTitle>{localization.onboardingFinishTitle}</FinishTitle>
        <Lottie
          options={{ loop: false, autoplay: true, animationData }}
          speed={0.75}
          eventListeners={[
            {
              eventName: "complete",
              callback: completed,
            },
          ]}
        ></Lottie>
      </FinishContainer>
    </FlexDiv>
  );
};

export default Finish;
