import React, { useContext } from "react";
import { OnboardingQuestionsContext } from "../../contexts/OnboardingQuestionsContextProvider";
import OnboardingCheckboxPage from "../../components/OnboardingCheckboxPage";

const WorkoutLevels = () => {
  const { workout_levels, totalNumSteps } = useContext(OnboardingQuestionsContext);
  return <OnboardingCheckboxPage page={workout_levels} totalNumSteps={totalNumSteps} />;
};

export default WorkoutLevels;
