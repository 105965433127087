import React, { useContext } from "react";
import IconHeader from "../../components/IconHeader";
import styled from "styled-components";
import RoundedButton from "../../components/RoundedButton";
import colors from "../../utils/colors";
import { Link } from "react-router-dom";
import config from "../../utils/config";
import { OnboardingQuestionsContext } from "../../contexts/OnboardingQuestionsContextProvider";

const FlexDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TextContainer = styled.div`
  margin: 0 64px;
  font-size: 16px;
  text-align: center;
`

const IntroTitle = styled.p`
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 16px;
  color: black;
`

const NextButtonContainer = styled.div`
  margin: auto 32px 32px 32px;
`

const ButtonText = styled.p`
  font-size: 15px;
  font-weight: bold;
  color: white;
`

const Intro3 = () => {
  const { localization } = useContext(OnboardingQuestionsContext);

  return (
    <FlexDiv>
      <IconHeader
        backgroundSrc={`${config.ICONS_BASE_URL}signup/red-intro-side2-bundled.svg`}
        iconSrc={localization.onboardingIntro3Icon}
        backgroundMaxHeight="55%"
        iconWidth="50%"
        iconMaxWidth="300px"
      />

      <TextContainer>
        <IntroTitle>{localization.onboardingIntro3Title}</IntroTitle>
        {/* pre-wrap styling allows \n within the string to create a new line */}
        <p style={{whiteSpace: "pre-wrap"}}>{localization.onboardingIntro3Description}</p>
      </TextContainer>

      <NextButtonContainer>
        <Link to={"/signup/workout_levels"}>
          <RoundedButton color={colors.red}>
            <ButtonText>{localization.onboardingIntro3ContinueButtonText}</ButtonText>
          </RoundedButton>
        </Link>
      </NextButtonContainer>
    </FlexDiv>
  );
};

export default Intro3;
