import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Redirect = (props: any) => {
  const location = useLocation();
  let navigate = useNavigate();

  React.useEffect(() => {
    navigate(props.to);
  }, [location]);

  return <></>;
};

export default Redirect;
