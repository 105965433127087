import React from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import { ThemeProvider } from "@aws-amplify/ui-react";
import { defaultTheme } from "@aws-amplify/ui-react";
import config from "../../utils/config";
import styled from "styled-components";

const styles = {
  continer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    width: "100%",
    padding: "12px 20px",
    margin: "8px 0",
    display: "inline-block",
    border: "1px solid #ccc",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  submit: {
    width: "100%",
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "14px 20px",
    margin: "8px 0",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

const Container = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  height: 45vh;
  justify-content: center;
  flex-direction: column;
`;

const Wrapper = styled.div`
  height: 100vh;
`;

const StretchBackground = styled.img`
  width: 100%;
  height: 30vh;
  position: relative;
`;

const { tokens } = defaultTheme;

const theme = {
  name: "my-theme",
  container: {
    border: "none",
  },
  tokens: {
    colors: {
      brand: {
        primary: {
          "10": tokens.colors.red["10"],
          "20": tokens.colors.red["20"],
          "40": tokens.colors.red["40"],
          "60": tokens.colors.red["60"],
          "80": tokens.colors.red["80"],
          "90": tokens.colors.red["90"],
          "100": tokens.colors.red["100"],
        },
      },
    },
  },
};

const formFields = {
  signIn: {
    username: {
      labelHidden: true,
      placeholder: "Email",
    },
  },
};

const components = {
  SignIn: {},
};

const SignIn = () => {
  const backgroundSrc = `${config.ICONS_BASE_URL}signup/grey-intro-bundled.svg`;

  return (
    <Wrapper>
      <StretchBackground alt="header-background" src={backgroundSrc} />
      <Container>
        <h2>Log In</h2>
        <ThemeProvider theme={theme}>
          <Authenticator
            hideSignUp={true}
            formFields={formFields}
            components={components}
          ></Authenticator>
        </ThemeProvider>
      </Container>
    </Wrapper>
  );
};

export default SignIn;
