import React, { useContext } from "react";
import { OnboardingQuestionsContext } from "../../contexts/OnboardingQuestionsContextProvider";
import OnboardingCheckboxPage from "../../components/OnboardingCheckboxPage";

const BodyAreas = () => {
  const { totalNumSteps, body_areas } = useContext(OnboardingQuestionsContext);
  return <OnboardingCheckboxPage page={body_areas} totalNumSteps={totalNumSteps} />;
};

export default BodyAreas;
