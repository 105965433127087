import React, { useContext } from "react";
import styled from "styled-components";
import RoundedButton from "../../components/RoundedButton";
import colors from "../../utils/colors";
import { Link } from "react-router-dom";
import IconHeader from "../../components/IconHeader";
import config from "../../utils/config";
import { OnboardingQuestionsContext } from "../../contexts/OnboardingQuestionsContextProvider";

const FlexDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TextContainer = styled.div`
  margin: 0 32px;
  font-size: 16px;
  text-align: center;
`

const IntroTitle = styled.p`
  font-size: 22px;
  font-weight: bold;
`

const IntroTextList = styled.ul`
  text-align: left;
`

const NextButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: auto 32px 32px 32px;
`

const Intro2 = () => {
  const { localization } = useContext(OnboardingQuestionsContext);

  return (
    <FlexDiv>
      <IconHeader
        backgroundSrc={`${config.ICONS_BASE_URL}signup/red-intro-side1-bundled.svg`}
        iconSrc={localization.onboardingIntro2Icon}
        backgroundMaxHeight="55%"
        iconWidth="70%"
        iconMaxWidth="450px"
      />

      <TextContainer>
        <IntroTitle>{localization.onboardingIntro2Title}</IntroTitle>
        {/* pre-wrap styling allows \n within the string to create a new line */}
        <p style={{whiteSpace: "pre-wrap"}}>{localization.onboardingIntro2Description}</p>
        <IntroTextList>
          {localization.onboardingIntro2List.split("\n").map((item: string) => (
            <li>{item}</li>
          ))}
        </IntroTextList>
      </TextContainer>

      <NextButtonContainer>
        <Link to={"/signup/intro3"}>
          <RoundedButton color={colors.red}><img alt="next" src={`${config.ICONS_BASE_URL}signup/arrow-white-icon.svg`} /></RoundedButton>
        </Link>
      </NextButtonContainer>
    </FlexDiv>
  );
};

export default Intro2;
