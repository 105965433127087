import { IdNameSlugResponse } from "../types";

export type GetStudioResponse = IdNameSlugResponse & {
  localization: GetStudioLocalizationResponse;
  workoutLevels: GetStudioWorkoutLevelResponse[];
  bodyAreas: GetStudioBodyAreasResponse[];
  activities: GetStudioActivityResponse[];
}

export type GetStudioLocalizationResponse = {
  onboardingIntro1Icon: string;
  onboardingIntro1PreTitleText: string;
  onboardingIntro1Title: string;
  onboardingIntro1Description: string;
  onboardingIntro1ContinueButtonText: string;

  onboardingIntro2Icon: string;
  onboardingIntro2Title: string;
  onboardingIntro2Description: string;
  onboardingIntro2List: string;

  onboardingIntro3Icon: string;
  onboardingIntro3Title: string;
  onboardingIntro3Description: string;
  onboardingIntro3ContinueButtonText: string;

  onboardingWorkoutLevelsQuestion: string;
  onboardingWorkoutLevelsIcon: string;
  onboardingBodyAreasQuestion: string;
  onboardingBodyAreasIcon: string;
  onboardingActivitiesQuestion: string;
  onboardingActivitiesIcon: string;
  onboardingScheduleDaysQuestion: string;
  onboardingScheduleDaysIcon: string;
  onboardingScheduleTimeQuestion: string;
  onboardingScheduleTimeIcon: string;
  onboardingPersonalDetailsQuestion: string;
  onboardingPersonalDetailsIcon: string;

  onboardingFinishIcon: string;
  onboardingFinishTitle: string;
};

type GetStudioWorkoutLevelResponse = IdNameSlugResponse;
type GetStudioBodyAreasResponse = IdNameSlugResponse;
type GetStudioActivityResponse = IdNameSlugResponse;

export const placeholderLocalization: GetStudioLocalizationResponse = {
  onboardingIntro1Icon: "",
  onboardingIntro1PreTitleText: "",
  onboardingIntro1Title: "",
  onboardingIntro1Description: "",
  onboardingIntro1ContinueButtonText: "",

  onboardingIntro2Icon: "",
  onboardingIntro2Title: "",
  onboardingIntro2Description: "",
  onboardingIntro2List: "",

  onboardingIntro3Icon: "",
  onboardingIntro3Title: "",
  onboardingIntro3Description: "",
  onboardingIntro3ContinueButtonText: "",

  onboardingWorkoutLevelsQuestion: "",
  onboardingWorkoutLevelsIcon: "",
  onboardingBodyAreasQuestion: "",
  onboardingBodyAreasIcon: "",
  onboardingActivitiesQuestion: "",
  onboardingActivitiesIcon: "",
  onboardingScheduleDaysQuestion: "",
  onboardingScheduleDaysIcon: "",
  onboardingScheduleTimeQuestion: "",
  onboardingScheduleTimeIcon: "",
  onboardingPersonalDetailsQuestion: "",
  onboardingPersonalDetailsIcon: "",

  onboardingFinishIcon: "",
  onboardingFinishTitle: "",
}

export const placeholderStudio: GetStudioResponse = {
  id: "",
  name: "",
  slug: "",
  localization: placeholderLocalization,
  workoutLevels: [],
  bodyAreas: [],
  activities: [],
}
