import React from "react";
import { Image } from "semantic-ui-react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
`

const Title = styled.p`
  color: #4B4B4B;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
`

type HeaderProps = {
  title: string;
  backButton?: boolean;
}

const Header = (props: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <Container>
      {props.backButton ? (
        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
          <Image src="/home-icons/arrow-back-icon.svg" />
        </div>
      ) : <div />}
      <Title>{props.title}</Title>
      {props.backButton ? (
        // Hidden element to keep title centered
        <Image src="/home-icons/arrow-back-icon.svg" style={{ visibility: "hidden" }}/>
      ) : <div />}

    </Container>
  );
}

export default Header;
