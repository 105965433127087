import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const UnauthenticatedHome = () => {
  return (
    <div>
      <h1>Unauthenticated Home Page</h1>
      <p>Some content should go here</p>

      <br />
      <br />
      <br />
      <br />
      <Link to={"/signup"}>Signup</Link>
      <br />
      <br />
      <br />
      <Link to={"/sign-in"}>Sign In</Link>
    </div>
  );
};

export default UnauthenticatedHome;
