import React from "react";
import RoundedButton from "./RoundedButton";
import ProgressBar from "./ProgressBar";
import colors from "../utils/colors";
import styled from "styled-components";
import ConditionalLink from "./ConditionalLink";
import config from "../utils/config";

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Message = styled.p`
  text-align: center;
  font-size: 13px;
  color: #77838f;
`;

const ConditionalHideDiv = styled.div`
  visibility: ${(props: { isHidden: boolean }) =>
    props.isHidden ? "hidden" : undefined};
`;

type ProgressNavigatorProps = {
  current: number;
  total: number;
  backPage?: string;
  nextPage: string;
  nextAction?: () => Promise<boolean>;
  backIsHidden: boolean;
  nextIsDisabled: boolean;
};

const ProgressNavigator = (props: ProgressNavigatorProps) => {
  return (
    <>
      <Message>You can change these anytime in your profile</Message>

      <FlexDiv>
        {/* Need to hide button if on the first page, but need to use {visibility: hidden} style to keep proper spacing */}
        <ConditionalHideDiv isHidden={props.backIsHidden}>
          <ConditionalLink
            to={props.backPage}
            condition={!props.backIsHidden && !!props.backPage}
          >
            <RoundedButton color={colors.white}>
              <img
                alt="back"
                src={`${config.ICONS_BASE_URL}signup/arrow-grey-icon.svg`}
              />
            </RoundedButton>
          </ConditionalLink>
        </ConditionalHideDiv>

        <ProgressBar current={props.current} total={props.total} />

        <ConditionalLink
          to={props.nextPage}
          action={props.nextAction}
          condition={!props.nextIsDisabled}
        >
          <RoundedButton
            disabled={props.nextIsDisabled}
            color={props.nextIsDisabled ? colors.darkGrey : colors.red}
          >
            <img
              alt="next"
              src={`${config.ICONS_BASE_URL}signup/arrow-white-icon.svg`}
            />
          </RoundedButton>
        </ConditionalLink>
      </FlexDiv>
    </>
  );
};

export default ProgressNavigator;
