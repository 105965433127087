import React from "react";
import Checkbox from "./Checkbox";
import styled from "styled-components";
import { CheckboxOption } from "../contexts/OnboardingQuestionsContextProvider";
import FlowGridCheckbox from "./FlowGridCheckbox";

const ListItem = styled.div`
  margin-bottom: ${(props: { isLast: boolean }) => !props.isLast ? "20px" : undefined};
`

const FlowGridContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  // margin of checkboxes * -1 - Allows checkboxes that are on sides to be flush with div without knowing which checkboxes are on sides
  margin: 0 -11px 0 -11px;
`

type CheckboxListProps = {
  options: CheckboxOption[];
  handleSelect: (id: string) => void;
  type?: "list" | "flowGrid";
}

const CheckboxList = (props: CheckboxListProps) => {
  if (!props.type || props.type === "list") {
    return (
      <>
        {props.options.map((option, index) => (
          <ListItem key={option.id} isLast={index === props.options.length - 1}>
            <Checkbox text={option.text} checked={option.checked} handleSelect={() => props.handleSelect(option.id)} />
          </ListItem>
        ))}
      </>
    );
  } else { // type === "flowGrid"
    return (
      <FlowGridContainer>
        {props.options.map(option =>
          <FlowGridCheckbox text={option.text} checked={option.checked} handleSelect={() => props.handleSelect(option.id)} />
        )}
      </FlowGridContainer>
    );
  }
};

export default CheckboxList;
