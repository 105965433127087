import React, { useContext } from "react";
import { OnboardingQuestionsContext } from "../../contexts/OnboardingQuestionsContextProvider";
import OnboardingPage from "../../components/OnboardingPage";
import styled from "styled-components";
import colors from "../../utils/colors";

const Input = styled.input`
  padding: 12px 24px;
  border-radius: 6px;
  color: inherit;
  margin-bottom: 24px;
  border: none;
  box-shadow: 0 4px 16px #E2E2E2;
  width: 100%;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const PersonalDetails = () => {
  const { totalNumSteps, personal_details } = useContext(OnboardingQuestionsContext);

  return (
    <OnboardingPage page={personal_details} totalNumSteps={totalNumSteps}>
      <Row>
        <Input
          placeholder="First Name"
          value={personal_details.fields.firstName}
          onChange={(event) => personal_details.fields.setFirstName(event.target.value)}
          style={{ marginRight: 14 }}
        />
        <Input
          placeholder="Last Name"
          value={personal_details.fields.lastName}
          onChange={(event) => personal_details.fields.setLastName(event.target.value)}
          style={{ marginLeft: 14 }}
        />
      </Row>
      <Input
        placeholder="Email"
        type="email"
        value={personal_details.fields.email}
        onChange={(event) => personal_details.fields.setEmail(event.target.value)}
      />
      <Input
        placeholder="Password"
        type="password"
        value={personal_details.fields.password}
        onChange={(event) => personal_details.fields.setPassword(event.target.value)}
      />
      <Input
        placeholder="Confirm Password"
        type="password"
        value={personal_details.fields.confirmPassword}
        onChange={(event) => personal_details.fields.setConfirmPassword(event.target.value)}
      />
      {
        !!personal_details.fields.confirmPassword
        && personal_details.fields.confirmPassword !== personal_details.fields.password
        && (
          <p style={{ color: colors.red }}>Passwords do not match</p>
        )
      }
    </OnboardingPage>
  );
};

export default PersonalDetails;
