import { GetWorkoutCollectionResponse, VideoCardResponse, VideoResponse } from "./types";
import ApiResponse from "../ApiResponse";
import { makeGetRequest } from "../utils";

// TODO Decide what is returned for home screen
export const home = async (): Promise<unknown> => {
  return null;
}

export const getAllWorkouts = async (): Promise<ApiResponse<GetWorkoutCollectionResponse[]>> =>
  await makeGetRequest<GetWorkoutCollectionResponse[]>("/api/workouts/all");

export const getMyWorkouts = async (): Promise<ApiResponse<VideoCardResponse[]>> =>
  await makeGetRequest<VideoCardResponse[]>("/api/workouts/me");

export const getWorkout = async (id: string): Promise<ApiResponse<VideoResponse>> =>
  await makeGetRequest<VideoResponse>(`/api/workouts/${id}`);
