import React from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";

const Navigation = () => {
  return (
    <ul>
      <li>
        <Link to={"/"}>Home</Link>
      </li>
      <li>
        Signup
        <ul>
          <li>
            <Link to={"/signup"}>Signup Intro1</Link>
          </li>
          <li>
            <Link to={"/signup/intro2"}>Signup Intro2</Link>
          </li>
          <li>
            <Link to={"/signup/intro3"}>Signup Intro3</Link>
          </li>
          <li>
            <Link to={"/signup/workout_levels"}>Signup WorkoutLevels</Link>
          </li>
          <li>
            <Link to={"/signup/body_areas"}>Signup BodyAreas</Link>
          </li>
          <li>
            <Link to={"/signup/activities"}>Signup Activities</Link>
          </li>
          <li>
            <Link to={"/signup/schedule_days"}>Signup ScheduleDays</Link>
          </li>
          <li>
            <Link to={"/signup/schedule_time"}>Signup ScheduleTime</Link>
          </li>
          <li>
            <Link to={"/signup/personal_details"}>Signup PersonalDetails</Link>
          </li>
          <li>
            <Link to={"/signup/finish"}>Signup Finish</Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to={"/workouts"}>My Workouts</Link>
      </li>
      <li>
        <Link to={"/profile"}>Profile</Link>
      </li>
      <li
        onClick={() => {
          Auth.signOut().then(() => {
            window.location.href = "/";
          });
        }}
      >
        Sign Out
      </li>
      <li>
        <Link to={"/invalid"}>NotFound</Link>
      </li>
    </ul>
  );
};

export default Navigation;
