import { CheckboxOption } from "../contexts/OnboardingQuestionsContextProvider";

export const handleToggleCheckBox = (
  id: string,
  options: CheckboxOption[],
  setOptions: (options: CheckboxOption[]) => void
) => {
  setOptions(
    options.map(option => (
      option.id === id ?
        {
          ...option,
          checked: !option.checked
        } :
        option
    ))
  );
};

export const handleChooseRadio = (
  id: string,
  options: CheckboxOption[],
  setOptions: (options: CheckboxOption[]) => void
) => {
  setOptions(
    options.map(option => (
      option.id === id ?
        {
          ...option,
          checked: true
        } :
        {
          ...option,
          checked: false
        }
    ))
  );
}