import React from "react";
import NavigationFooter from "./NavigationFooter";
import styled, { css } from "styled-components";

const Container = styled.div`${(props: { marginX: number }) => css`
  padding: 0 ${props.marginX}px 66px ${props.marginX}px;
`}`

type ContainerWithNavigationFooterProps = {
  children: React.ReactNode;
  marginX?: number;
}

const ContainerWithNavigationFooter = ({
    children,
    marginX = 28,
  }: ContainerWithNavigationFooterProps) => {
  return (
    <>
      <Container marginX={marginX}>
        {children}
      </Container>
      <NavigationFooter />
    </>
  );
};

export default ContainerWithNavigationFooter;
