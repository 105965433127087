import React from "react";
import "./App.css";
import AppContainer from "./AppContainer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Home,
  MyWorkouts,
  AllWorkouts,
  Workout,
  ScheduleWorkout,
  Profile,
  NotFound,
} from "./pages";
import {
  Intro1,
  Intro2,
  Intro3,
  WorkoutLevels,
  BodyAreas,
  Activities,
  ScheduleDays,
  ScheduleTime,
  PersonalDetails,
  Finish,
} from "./pages/signup";
import SignIn from "./pages/unauthenticated/SignIn";
import AppLayout from "./layouts/AppLayout";
import { OnboardingQuestionsContextProvider } from "./contexts/OnboardingQuestionsContextProvider";
import UnauthenticatedHome from "./pages/unauthenticated/Home";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Redirect from "./pages/Redirect";
import ContainerWithNavigationFooter from "./components/ContainerWithNavigationFooter";
import ManifestHandler from "./components/ManifestHandler";

function App() {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  return (
    <AppContainer>
      <BrowserRouter>
        {authStatus === "authenticated" ? (
          <Routes>
            <Route path="/" element={<AppLayout />}>
              <Route
                index
                element={
                  <ContainerWithNavigationFooter>
                    <ManifestHandler api="/api/home" />
                  </ContainerWithNavigationFooter>
                }
              />
              <Route path="workouts" element={<MyWorkouts />} />
              <Route path="workouts/all" element={<AllWorkouts />} />
              <Route path="workouts/:id" element={<Workout />} />
              <Route
                path="workouts/:id/schedule"
                element={<ScheduleWorkout />}
              />
              <Route path="profile" element={<Profile />} />
              <Route path="*" element={<Redirect to={"/"} />} />
            </Route>
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<AppLayout />}>
              <Route index element={<UnauthenticatedHome />} />
              <Route path="sign-in" element={<SignIn />} />
              <Route path="login" element={<SignIn />} />
              <Route
                path="signup"
                element={
                  <div style={{ height: "100vh" }}>
                    <OnboardingQuestionsContextProvider />
                  </div>
                }
              >
                <Route index element={<Intro1 />} />
                <Route path="intro2" element={<Intro2 />} />
                <Route path="intro3" element={<Intro3 />} />
                <Route path="workout_levels" element={<WorkoutLevels />} />
                <Route path="body_areas" element={<BodyAreas />} />
                <Route path="activities" element={<Activities />} />
                <Route path="schedule_days" element={<ScheduleDays />} />
                <Route path="schedule_time" element={<ScheduleTime />} />
                <Route path="personal_details" element={<PersonalDetails />} />
                <Route path="finish" element={<Finish />} />
              </Route>
              <Route path="*" element={<Redirect to={"/"} />} />
            </Route>
          </Routes>
        )}
      </BrowserRouter>
    </AppContainer>
  );
}

export default App;
