import React, { useContext } from "react";
import styled from "styled-components";
import RoundedButton from "../../components/RoundedButton";
import colors from "../../utils/colors";
import { Link } from "react-router-dom";
import config from "../../utils/config";
import { OnboardingQuestionsContext } from "../../contexts/OnboardingQuestionsContextProvider";

const HeaderContainer = styled.div`
  width: 100%;
  max-height: ${(props: { backgroundMaxHeight: string }) => props.backgroundMaxHeight};
  position: relative; // Needed for CenterOnBackground div to have correct height
`

const CenterOnBackground = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StretchBackground = styled.img`
  width: 100%;
  height: 100%;
`

const ConstrainedWidthDiv = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: ${(props: { iconWidth: string, iconMaxWidth: string }) =>
  props.iconWidth};
  max-width: ${(props: { iconWidth: string, iconMaxWidth: string  }) =>
  props.iconMaxWidth}
`

const ConstrainedWidthIcon = styled.img`
  width: ${(props: { iconWidth: string, iconMaxWidth: string }) =>
  props.iconWidth};
  max-width: ${(props: { iconWidth: string, iconMaxWidth: string  }) =>
  props.iconMaxWidth}
`



const FlexDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TextContainer = styled.div`
  margin: 0 32px;
  font-size: 16px;
  text-align: center;
`

const IntroTitle = styled.p`
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 16px;
  color: black;
`

const NextButtonContainer = styled.div`
  margin: auto 32px 32px 32px;
  font-size: 15px;
`

const ButtonText = styled.p`
  font-weight: bold;
  color: white;
`

const LoginOptionContainer = styled.div`
  margin: 23px 0;
  color: #77838F;
  text-align: center;
`

const Intro1 = () => {
  const { localization } = useContext(OnboardingQuestionsContext);

  const backgroundSrc = `${config.ICONS_BASE_URL}signup/grey-intro-bundled.svg`;
  const backgroundMaxHeight = "60%";
  const iconWidth = "85%";
  const iconMaxWidth = "500px";

  return (
    <FlexDiv>
      <HeaderContainer backgroundMaxHeight={backgroundMaxHeight}>
        <StretchBackground alt="header-background" src={backgroundSrc} />

        <CenterOnBackground>
          <ConstrainedWidthDiv
            iconWidth={iconWidth}
            iconMaxWidth={iconMaxWidth}
          >
            <ConstrainedWidthIcon
              alt="icon"
              src={localization.onboardingIntro1Icon}
              iconWidth={iconWidth}
              iconMaxWidth={iconMaxWidth}
            />
          </ConstrainedWidthDiv>
        </CenterOnBackground>
      </HeaderContainer>


      <TextContainer>
        <p>{localization.onboardingIntro1PreTitleText}</p>
        <IntroTitle>{localization.onboardingIntro1Title}</IntroTitle>
        {/* pre-wrap styling allows \n within the string to create a new line */}
        <p style={{whiteSpace: "pre-wrap"}}>{localization.onboardingIntro1Description}</p>
      </TextContainer>

      <NextButtonContainer>
        <Link to={"/signup/Intro2"}>
          <RoundedButton color={colors.red}>
            <ButtonText>{localization.onboardingIntro1ContinueButtonText}</ButtonText>
          </RoundedButton>
        </Link>

        <LoginOptionContainer>
          <Link to="/login" style={{ color: "inherit" }}>
            Or <span style={{ fontWeight: "bold" }}>Login</span>
          </Link>
        </LoginOptionContainer>
      </NextButtonContainer>
    </FlexDiv>
  );
};

export default Intro1;
