import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

export const MAX_WIDTH = 1024;

const Container = styled.div`
  margin: auto;
  max-width: ${MAX_WIDTH}px;
  min-height: 100vh;
  background-color: #FAFAFA;
`

const AppLayout = () => {
  return (
    <Container>
      <Outlet />
    </Container>
  )
}

export default AppLayout;
