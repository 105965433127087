import React from "react";
import styled  from "styled-components";

const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &.large > * {
    margin: 0 10px 10px 0;
  }
  &.regular > * {
    margin: 0 6px 6px 0;
  }
  &.mini > * {
    margin: 0 3px 3px 0;
  }
  & > :last-child {
    margin-right: 0;
  }
`

const Tag = styled.div`
  border-radius: 100px;

  &.large {
    padding: 4px 16px;
    font-size: 13px;
    font-weight: bold;
  }
  &.regular {
    padding: 4px 16px;
    font-size: 11px;
  }
  &.mini {
    padding: 3px 6px;
    font-size: 9px;
    line-height: 9px;
    font-weight: bold;
  }

  &.primary {
    background-color: #FF5555;
    color: white;
  }
  &.secondary {
    background-color: white;
    color: #44474A;
    border: 1px solid #E0DBDB;
  }
`

type TagCollectionProps = {
  tags: string[];
  size?: "large" | "regular" | "mini";
  secondary?: boolean;
}

const TagCollection = ({
  tags,
  size = "regular",
  secondary = false,
}: TagCollectionProps) => {
  return (
    <List className={size}>
      {tags.map((tag, index) => (
        <Tag key={index} className={`${size} ${secondary ? "secondary" : "primary"}`}>
          {tag}
        </Tag>
      ))}
    </List>
  );
};

export default TagCollection;
